// extracted by mini-css-extract-plugin
export var Bold = "GraffitiGlossaire-module--Bold--+yGyG";
export var ContactLink = "GraffitiGlossaire-module--ContactLink--COLaU";
export var Contactlink = "GraffitiGlossaire-module--Contactlink--secVD";
export var DateWrapper = "GraffitiGlossaire-module--DateWrapper--kP2WO";
export var DevelopmentWrapper = "GraffitiGlossaire-module--DevelopmentWrapper--lKt4b";
export var LegalMentionsWrapper = "GraffitiGlossaire-module--LegalMentionsWrapper--RT5aD";
export var Link = "GraffitiGlossaire-module--Link--hCQOZ";
export var NameValueWrapper = "GraffitiGlossaire-module--NameValueWrapper--ZtNaD";
export var RowWrapper = "GraffitiGlossaire-module--RowWrapper--XJ1cf";
export var Wrapper = "GraffitiGlossaire-module--Wrapper--wNz6m";
export var link = "GraffitiGlossaire-module--link--p-Mrk";