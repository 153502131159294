import * as React from "react";
import {
  Wrapper,
  LegalMentionsWrapper,
  RowWrapper,
  DateWrapper,
} from "./GraffitiGlossaire.module.css";
import { GrayTitle } from "../../../../css/Title.module.css";

const HistoireGlossaire = () => {
  return (
    <div className={Wrapper}>
      <h1 className={GrayTitle} >
        GLOSSARY
      </h1>
      
      <div className={LegalMentionsWrapper}>
        <div className={RowWrapper}>
          <div>
            <h1 style={{display: "flex", flexDirection: "row", alignItems: "flex-end"}}>Expérience de rue <h3 style={{paddingLeft: "10px"}}>glossaire de rue</h3></h1>
            <p> 1969-1972 premiers graffeurs New-Yorkais: Taki 183, PhaseII, StayHigh 149, Snake1, Coco 144 , Futura 2000, Blade, Tracy 168, Noc 167…</p>
            <br/>
            <p><strong>1973</strong> : Seen, Quik ...</p>
            <br/>
            <p > <strong>1975</strong> : Dondi White, Iz the Wiz, KaseII, Fab5Freddy, Freedom, Lee Quinones…</p>
            <br/>
           <p > <strong>1976</strong>: Crash, Zephyr, Bill Blast, Lady Pink, Revolt, Daze, Duro, Toxic, Aone, Rammellzee, Aone, ero</p>
            <br/>
            <p> <strong>1980's</strong>: Sharp, Delta2, Jonone…</p>
            <br/>
            <br/>
            <br/>
            <p > <strong>BLAZE</strong>: pseudonyme, nom de scène de l’artiste.</p>
            <br/>
            <p ><strong>BLOCK LETTER</strong>: type of lettering with square or rectangular shapes, usually done with a fat cap or roller.</p>
            <br/>
            <p ><strong> BUBBLE</strong>: letters with rounded outlines.</p>
            <br/>
            <p > <strong>CAP</strong>: This is the part of the bomb through which the paint is projected under the pressure of the finger. The tips are interchangeable to vary the thickness of the line (fat cap, skinny, etc.).</p>
            <br/>
            <p > <strong>CREW</strong>: refers to a group of artists who work together under one and only same name. The crew participates often in collective works where everyone signs with his one blaze. Artists can come from different places (dance, graffiti, music, etc...).</p>
            <br/>
            <p > <strong>FAT CAP</strong>: refers to a nozzle with a large jet. This nozzle is often used for fast work, pattern filling and is generally not a guarantee of precision.</p>
            <br/>
            <p > <strong>FLOP</strong>: rounded lettering.</p>
            <br/>
            <p > <strong>GETTING-UP:</strong> action of realizing graffiti on a subway car.</p>
            <br/>
            <p > <strong>MONOLAYER</strong>: stencil made with a single matrix but which does not exclude the use of several colors.</p>
            <br/>
            <p > <strong>OUTLINE</strong>: these are the contours of a letter, of a form. This term is often used when we talk about lettering.</p>
            <br/>
            <p > <strong>PIECES</strong>: abbreviation of masterpiece or the execution of a more complicated and stylized lettering with multiple colors. This form usually requires the application of a background.</p>
            <br/>
            <p > <strong>POSSE</strong>: see crew. More commonly used when dealing with gangs, in a vandal meaning.</p>
            <br/>
            <p > <strong>SKINNY</strong>:  type of nozzle allowing the realization of a fine and precise line.</p>
            <br/>
            <p > <strong>TAG</strong>: the most basic form of modern graffiti which is the signature of the crew/writer with a simple color.</p>
            <br/>
            <p > <strong>THROW-UP</strong>: it’s two or four letters that represent the artist/crew name in the Bubble style with one color marked for the outline and another for the filling.</p>
            <br/>
            <p > <strong>TRASHPAINT</strong>: poor quality spray paint.</p>
            <br/>
            <p > <strong>WILD STYLE</strong>: deformation of lettering or interlacing of stylized lettering whose reading is reserved for insiders (see Bazin).</p>
            <br/>
            <p > <strong>WRITER</strong>: artists who show themselves by painting their blaze.</p>
            <br/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoireGlossaire;
